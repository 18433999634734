import React from 'react';

import { AdminApiPrefix, Backend, BackendType, CortexConfig } from '@common/types';

export type BackendContextObject = {
  backend: Backend;
  adminApiPrefix?: AdminApiPrefix;
  config?: CortexConfig;
  refreshConfig: Function;
};

// If a context is requested, and none is available this object will be provided.
// In practice, no components which require the backend should be requesting the
// context if the backend is unknown.
// Note: `getBackendContextObject` is used to generate all used contexts
const NO_BACKEND: Backend = {
  isBackend: false,
  backendError: false,
  name: 'No backend' as BackendType,
  version: 'N/A',
  accessPolicyScopeFeatures: { availableScopes: [], defaultScopes: [] },
  features: {},
  implicitFeatures: {
    ringTypes: [],
    tenantCustomizableLimits: [],
    tenantStatsEnabled: false,
  },
};

export const DEFAULT_BACKEND_CONTEXT: BackendContextObject = {
  backend: NO_BACKEND,
  refreshConfig: () => undefined,
};

export const getBackendContextObject = (
  backend?: Backend | null,
  adminApiPrefix?: AdminApiPrefix,
  backendError?: unknown,
  config?: CortexConfig,
  refreshConfig?: () => unknown
) => {
  const compositeBackend = {
    ...NO_BACKEND, // BASE
    ...backend, // If provided, this will override base
    ...(backendError ? { backendError: true } : {}), // Declare error if reported
  };

  return {
    ...DEFAULT_BACKEND_CONTEXT, // BASE values will remain
    ...(config ? { config } : {}), // if present
    ...(refreshConfig ? { refreshConfig } : {}), // if present
    backend: compositeBackend,
    adminApiPrefix,
  };
};

export const BackendContext = React.createContext<BackendContextObject>(DEFAULT_BACKEND_CONTEXT);
