import React, { useMemo } from 'react';

import { Alert } from '@grafana/ui';

import { getEnterpriseLicenseExpiryDate, hasValidLicense } from '@common/utils';

export const EnterpriseLicenseAlerts = () => {
  const expiry = useMemo(getEnterpriseLicenseExpiryDate, []);
  const evaluation = hasValidLicense();

  if (expiry !== undefined && expiry?.getTime() <= Date.now()) {
    return (
      <Alert severity={evaluation.valid ? 'warning' : 'error'} title="Expired license">
        Your Grafana Enterprise license expired on {expiry.toLocaleDateString()}.
        {evaluation.valid
          ? `This license will remain usable for one week past the expiry date. `
          : `This plugin is not usable without a valid license. `}
        Please contact Grafana Labs for renewal.
      </Alert>
    );
  }
  if (evaluation.valid) {
    return null;
  }
  const { message } = evaluation;
  return (
    <Alert severity="error" title="Invalid license">
      <>This plugin requires a valid Grafana Enterprise. </>
      {message && <>{message} </>}
      Please contact Grafana Labs for renewal.
    </Alert>
  );
};
