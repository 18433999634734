import { AdminApiPrefix, BackendFeatures, FeaturesResult } from '@common/types';
import { apiGet, getAccessPolicyScopeFeatures, getImplicitFeatures } from '@common/utils';

const BASE_URL = '/features';

type LegacyProductFeatures = {
  editable_instances?: 'v1'; // v1 admin api backend uses this field instead of `editable_tenants`
};

type FeatureResponse = FeaturesResult & {
  features: Partial<BackendFeatures> & LegacyProductFeatures;
};

/** Fetch from the admin API `/features` endpoint, then and use some inferences to transform value. */
export const getFeatures = async (prefix: AdminApiPrefix) => {
  const response = await apiGet<FeatureResponse>(`${prefix}/${BASE_URL}`, { showErrorAlert: false });

  // Transform data from response...
  const { name, features, version } = response.data;
  const {
    block_upload,
    debug_export,
    editable_access_policies,
    editable_instances,
    editable_tenants,
    federated_queries,
    federated_rules,
    lbac,
    self_monitoring,
  } = features;

  // To expected `Backend` struct...
  const featuresResult: FeaturesResult = {
    name,

    features: {
      block_upload,
      debug_export,
      editable_access_policies,
      // The name of `editable_tenants` was changed from `editable_instances` so we funnel from both
      editable_tenants: editable_instances || editable_tenants,
      // GEM always had `federated_queries` but they were added to then `features` struct much later for GEL etc.
      federated_queries: name === 'GEM' ? 'v1' : federated_queries,
      federated_rules,
      lbac,
      self_monitoring,
    },
    version,
  } as const;

  return featuresResult;
};

export const getBackendFeatures = async (prefix?: AdminApiPrefix) => {
  if (!prefix) {
    return null;
  }

  const featureEndpointResult = await getFeatures(prefix);
  const { name, features } = featureEndpointResult;
  const backendFeatures = {
    isBackend: true,
    ...featureEndpointResult,
    accessPolicyScopeFeatures: getAccessPolicyScopeFeatures(name, features),
    implicitFeatures: getImplicitFeatures(name),
  };
  return backendFeatures;
};
