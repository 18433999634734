import { useEffect, useState } from 'react';

import { AppPluginMeta, GrafanaPlugin, KeyValue } from '@grafana/data';

import { getPlugin, updatePlugin } from '@common/api';
import { changeBackendAction, queryCache, store } from '@common/state';
import { getPluginApiPrefix } from '@common/types';
import { pluginPost } from '@common/utils';

let cachedPluginJsonData: any = undefined;

// Put this at the root of a plugin page
export const usePluginCache = <T extends KeyValue<any>>(plugin?: GrafanaPlugin<AppPluginMeta<T>>) => {
  const [meta, setMeta] = useState(plugin?.meta);

  useEffect(() => {
    if (meta?.jsonData !== cachedPluginJsonData) {
      cachedPluginJsonData = meta?.jsonData;
    }
  }, [meta?.jsonData]);

  const setPluginJsonData = async (jsonData?: T, secureJsonData?: KeyValue<any>) => {
    const { pinned, enabled } = meta || {};

    const newMeta = {
      pinned,
      enabled,
      jsonData: { ...meta?.jsonData, ...jsonData },
      ...(secureJsonData && Object.keys(secureJsonData).length ? { secureJsonData } : {}),
    };
    if (plugin?.meta.id) {
      await updatePlugin(plugin.meta.id, newMeta);
      await pluginPost(getPluginApiPrefix(plugin.meta.id), 'updatedSettings');
      queryCache.find(['features'])?.reset();

      const reloadedMeta = await getPlugin<T>(plugin.meta.id);
      // The rest of Grafana outside of our React tree will rely on `plugin.meta` this for the new values
      plugin.meta.jsonData = reloadedMeta.jsonData;
      // Our React tree will make use of this new state.

      // In about five seconds, we want to reload everything...
      await new Promise((resolve) => setTimeout(resolve, 5000));
      // See: https://github.com/grafana/grafana/pull/63279
      // When Grafana 10.1 becomes our target major version of support, we will be able to drop this 5 second wait,
      // and any of the backend infrastructure that supports it, e.g., the `/updatedSettings` endpoint.

      setMeta(reloadedMeta);
      store.dispatch(changeBackendAction);
      queryCache.findAll().forEach((query) => query.invalidate());
    }
  };

  return { setPluginJsonData, pluginMeta: meta };
};
