import { config } from '@grafana/runtime';

import { License } from '@common/types';

// In case any of the tokens under the licenses are empty we consider it as the app is licensed through AWS.
// More information on why the logic works like this:
// https://docs.google.com/document/d/1SN1-PemSGYR3xVsyVvUng9ATKvHFno1_3iO6WImltH8/edit?ts=60e2cc08
export const isLicensedThroughAWS = (licenses: License[] = []) => licenses.some(({ token }) => !token);

const secondsPerDay = 60 * 60 * 24;
const defaultExpiryWarningDays = 14;

export const getExpiredAndNearExpiredLicenses = (licenses: License[] = []) => {
  const nowInSeconds = Date.now() / 1000; // Truncate milliseconds
  const expired: License[] = [];
  const nearExpired: License[] = [];
  const notExpired: License[] = [];

  licenses.forEach((license) => {
    const expiry = license.token?.exp;
    if (!expiry) {
      return;
    }

    const secondsToExpiry = expiry - nowInSeconds;

    if (secondsToExpiry <= 0) {
      expired.push(license);
      return;
    }

    const daysToExpiry = secondsToExpiry / secondsPerDay;
    const warningThresholdInDays = license.token?.lic_exp_warn_days || defaultExpiryWarningDays;

    if (daysToExpiry <= warningThresholdInDays) {
      nearExpired.push(license);
    }

    notExpired.push(license);
  });

  return { expired, nearExpired, notExpired };
};

export const getEnterpriseLicenseExpiryDate = () => {
  const {
    licenseInfo: { expiry },
  } = config;

  if (expiry === undefined) {
    return undefined;
  }

  const expiryDate = new Date(expiry * 1000);
  // This will be scraped from production builds. This is for the benefit of the developer.
  console.info('Grafana Enterprise License expires on:', expiryDate);
  return expiryDate;
};

export type LicenseEvaluation =
  | {
      valid: true;
    }
  | {
      message?: string;
      valid: false; // If `valid` is false, there might be a message
    };

// Tells if the Grafana has a valid Enterprise license
export const hasValidLicense = (): LicenseEvaluation => {
  const { licenseInfo } = config;
  // hasValidLicense was deprecated and removed in 8.4.x
  // See https://github.com/grafana/gex-plugins/pull/528 for details
  if ('hasValidLicense' in licenseInfo) {
    // In this case, we handle grafana-enterprise versions <8.4
    // Currently using 8.4+ dependency, so LicenseInfo type doesn't have the 'hasValidLicense' property
    // hence, we must us `as any`.
    return { valid: (licenseInfo as any).hasValidLicense };
  }
  if ('enabledFeatures' in licenseInfo) {
    // In this case, we handle grafana-enterprise versions >=8.4
    // currently using 8.4+ dependency, so LicenseInfo type has the 'enabledFeatures' property
    // hence, we can reference it directly
    return { valid: licenseInfo.enabledFeatures['enterprise.plugins'] };
  }

  const message = `Unable to determine the license due to an incompatible licenseInfo object. This may be an issue with Grafana v${config.buildInfo.version}`;
  console.error(message, licenseInfo);
  return {
    message,
    valid: false,
  };
};
