import React from 'react';
import { Provider } from 'react-redux';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { storeMock as defaultStoreMock } from '@common/state';
import { getStore } from '@common/state/src/store';

type Props = {
  children: React.ReactNode;
  storeMock?: any;
};

// Heads up! Use the <TestWrapper /> component instead which uses this one under the hood.
// Can be used to wrap components with a mocked redux-store (tests, stories)
export const MockStore: React.FC<Props> = ({ children, storeMock = defaultStoreMock }) => {
  const store = getStore({ initialState: storeMock });

  return (
    <Provider store={store}>
      <QueryClientProvider client={mockQueryClient}>{children}</QueryClientProvider>
    </Provider>
  );
};

export const mockQueryCache = new QueryCache();

export const mockQueryClient = new QueryClient({
  queryCache: mockQueryCache,
  defaultOptions: {
    queries: {
      keepPreviousData: false,
      staleTime: 5000,
    },
  },
});
