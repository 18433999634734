import { useSelector } from 'react-redux';

import { IconName } from '@grafana/ui';

import { selectRingMetadata } from '@common/state/src/rings';
import { RingType } from '@common/types';

import t, { getRingLabel } from './text';

export const useRingRadioOptions = (ringTypes: RingType[]) => {
  // Warning: This is using the singleton cluster ring metadata
  const ringMetadata = useSelector(selectRingMetadata);

  const getOptionParams = (ringType: RingType) => {
    const { activeMemberIds, serviceAvailable, shardingDisabled, unhealthyMemberIds } = ringMetadata[ringType];

    let description: string = t.ringOption.healthy(ringType);

    if (!serviceAvailable) {
      description = t.ringOption.disabled(ringType);
    } else if (shardingDisabled) {
      description = t.ringOption.shardingDisabled(ringType);
    } else if (activeMemberIds.length === 0) {
      description = t.ringOption.pending(ringType);
    }

    let icon: IconName | undefined = undefined;
    let alert = false;

    if (serviceAvailable && !shardingDisabled) {
      const unhealthyMembers = unhealthyMemberIds.length;

      if (unhealthyMembers > 0) {
        icon = 'exclamation-triangle';
        alert = true;
        description = t.ringOption.unhealthy(ringType, unhealthyMembers);
      }
    }

    return {
      alert,
      description,
      disabled: !serviceAvailable,
      icon,
      label: getRingLabel(ringType),
      value: ringType,
    };
  };

  // When presenting the rings as options, sort them in alphabetical order
  return ringTypes.map(getOptionParams).sort((a, b) => a.label.localeCompare(b.label));
};
