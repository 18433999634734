import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';

import { Alert } from '@grafana/ui';

import { getBackendFeatures, getConfig } from '@common/api';
import { ApiPrefixes, changeApiPrefixesAction } from '@common/state/src/apiPrefixes/apiPrefixes.actions';
import { useLatestAdminApiVersion } from '@common/state/src/gex/gex.hooks';
import { AdminApiPrefix, getAdminApiPrefix, getPluginApiPrefix } from '@common/types';
import { BackendContext, getBackendContextObject, usePluginMeta } from '@common/utils';

import { PluginPage } from '../PluginPage';

type Props = {
  children: ReactNode[] | ReactNode;
};

export const BackendContextWrapper = ({ children }: Props) => {
  const { pluginMeta, pluginResourceUrlPrefix } = usePluginMeta();
  const dispatch = useDispatch();
  const { latestVersion: latestAdminApiVersion, error: adminApiVersionError } = useLatestAdminApiVersion();

  const [adminApiPrefix, setAdminApiPrefix] = useState<AdminApiPrefix>();

  useEffect(() => {
    if (pluginMeta && latestAdminApiVersion) {
      // Redux store needs to know the prefixes.
      const prefixes: ApiPrefixes = {
        adminApiPrefix: getAdminApiPrefix(pluginMeta.id, pluginMeta.jsonData?.adminApiVersion || latestAdminApiVersion),
        pluginPrefix: getPluginApiPrefix(pluginMeta.id),
      };
      dispatch(changeApiPrefixesAction(prefixes));
      setAdminApiPrefix(prefixes.adminApiPrefix);
    }
  }, [pluginMeta, latestAdminApiVersion, dispatch]);

  // First the features for the current backend must be obtained
  const { data: backend, error: backendError } = useQuery(
    [
      adminApiPrefix,
      'features',
      // when we consider multiple backends, we can add the current backend's identifier here
    ],
    () => getBackendFeatures(adminApiPrefix)
  );

  // The backend type selected implicitly determines the `configFetchUrl`

  const configFetchUrl = backend?.implicitFeatures.configFetchUrl;

  const fetchConfig = useCallback(() => {
    if (!configFetchUrl) {
      // Don't fetch anything while we don't have a defined URL.
      return {};
    }
    return getConfig(pluginResourceUrlPrefix, configFetchUrl);
  }, [configFetchUrl, pluginResourceUrlPrefix]);

  const { data: config, refetch: refreshConfig } = useQuery(
    [
      configFetchUrl, // trigger a fetch on defined (or redefined) `configFetchUrl`
      'config',
      // when we consider multiple backends, we can add the current backend's identifier here
    ],
    fetchConfig
  );

  if (adminApiVersionError === 'internal resource not found') {
    // GEL behaves strangeley if the license isn't enabled. It fails to get the admin API version, because test calls to the admin api are rejected with 404.
    // So we make this special case.
    return (
      <PluginPage>
        <Alert
          severity="error"
          title={
            `Unable to connect to the backend database admin API. ` +
            `This may be due to a misconfiguration in the backend database gateway service ` +
            `Ensure your gateway URLs are correctly configured (if you are using that service).` +
            `If the database is GEL, this may be due to the service being initialized with an expired or invalid license. ` +
            `Ensure your license is up to date, and is correctly associated with your cluster identifier before restarting your enterprise database and trying again. ` +
            `Please contact Grafana Labs for assistance with your license.`
          }
        />
      </PluginPage>
    );
  } else if (adminApiVersionError === 'database unavailable') {
    return (
      <PluginPage>
        <Alert
          severity="error"
          title={
            `Unable to connect to the backend database. ` +
            `Either the database backend URL is not correct, or the database is not currently available. `
          }
        />
      </PluginPage>
    );
  }

  const context = getBackendContextObject(backend, adminApiPrefix, backendError, config, refreshConfig);

  // TODO ensure we associate the state storage strategy
  // with the currently selected backend for admin objects.
  // Then in the future, when we handle multiple backends,
  // when we switch to a different backend, we will swap to
  // use the selected backend's state store.

  // Wrap the children in the context
  return <BackendContext.Provider value={context}>{children}</BackendContext.Provider>;
};
