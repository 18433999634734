import React from 'react';
import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { config as RuntimeConfig } from '@grafana/runtime';
import { useStyles2 } from '@grafana/ui';

import { ActionButtonSet } from '../ActionButtonSet';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    actionButtonSet: css`
      position: absolute;
      top: -${theme.spacing(8)};
      right: -${theme.spacing(3)};
    `,
    relative: css`
      position: relative;
    `,
  };
};

export const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const styles = useStyles2(getStyles);

  return (
    <>
      {!RuntimeConfig?.featureToggles.topnav && (
        <div className={styles.relative}>
          <ActionButtonSet className={styles.actionButtonSet} />
        </div>
      )}
      {children}
    </>
  );
};
