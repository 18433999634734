import { AdminResource, AdminResourceStatus } from '../AdminApi';

export const getOriginalObjectIfBlank = <T extends AdminResource>(persisted: PotentiallyBlank<T>, original: T) => {
  if (!isNotBlank<T>(persisted)) {
    // Due to a bug in backend AdminAPI, the `persisted...` admin object
    // returned by `create...` may be a blank object.
    // To work around this possibility, we return the original,
    // so that redux may use it for updating the front-end state.
    // See https://github.com/grafana/backend-enterprise/pull/3102 - Which as a fix will appear from 2022-03 onward
    //
    // After we stop official support for any AdminAPI versions before v3, we can safely rely on the `persisted...` object,

    const status: AdminResourceStatus = 'active';
    return { ...original, status };
  }
  return persisted;
};

export function isNotBlank<T>(value: PotentiallyBlank<T>): value is T {
  // Prior to 2022-03, admin API POST requests returned nothing, resulting in blank objects:
  // - '' for Grafana versions prior to 8.4.2
  // - {} for Grafana versions after 8.4.2 (see https://github.com/grafana/grafana/pull/45598)
  // This type checker will verify that the given `value` is not blank.
  // These checks can be removed once admin API v1 and v2 support is no longer required.

  // If all of these are true, then value is not blank
  return value !== '' && value != null && Object.keys(value).length > 0;
}

// Prior to 2022-03, admin API POST requests returned nothing, resulting in blank objects:
// - '' for Grafana versions prior to 8.4.2
// - {} for Grafana versions after 8.4.2 (see https://github.com/grafana/grafana/pull/45598)
// This type indicates that `T` is potentially blank
export type PotentiallyBlank<T> = T | {} | '' | null | undefined;
