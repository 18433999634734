import { PluginApiPrefix, RingMember, RingMemberState } from '@common/types';
import { pluginGet, pluginPost } from '@common/utils';

// Fetched object doesn't declare 'type', defines 'state' differently
type FetchedRingMember = Omit<RingMember, 'type' | 'state'> & {
  // Redefine state to include alternate case
  state: RingMemberState | 'Unhealthy';
};

export const getRingStatus = async (prefix: PluginApiPrefix, ringType: string, showErrorAlert = true) => {
  const { data } = await pluginGet<{ shards: FetchedRingMember[] } | string>(prefix, `/${ringType}/ring`, {
    showErrorAlert,
  });

  // Infer that sharding is disabled, because we are receiving an http text page warning us as such
  if (typeof data === 'string') {
    return {
      ringMembers: [],
      shardingDisabled: true,
    };
  }
  const ringMembers = data.shards;

  if (ringMembers) {
    // Normalize ring members for use in our frontend
    ringMembers.map((member: FetchedRingMember) => {
      return {
        ...member,
        // Add our ring type to the object
        type: ringType,
        // Normalize all states to UNHEALTHY, some legacy rings use "Unhealthy" instead.
        state: member.state === 'Unhealthy' ? 'UNHEALTHY' : member.state,
      } as RingMember;
    });
  }

  return {
    ringMembers,
    shardingDisabled: false,
  };
};

export const forgetRingMember = async (prefix: PluginApiPrefix, ringType: string, id: string) =>
  pluginPost(prefix, `/${ringType}/ring`, {
    data: {
      forget: id,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
