import { LegacyAdminApiV1 } from './legacy';
import { getPluginApiPrefix, PluginApiPrefix } from './Plugin';

export const ADMIN_API_VERSIONS = ['v1', 'v2', 'v3'] as const;
export type AdminApiVersion = (typeof ADMIN_API_VERSIONS)[number];

const adminApiVersionSet = new Set<string | undefined | null>(ADMIN_API_VERSIONS);
export function isAdminApiVersion(version: string | undefined | null): version is AdminApiVersion {
  return adminApiVersionSet.has(version);
}

export const getVersionNumber = (prefix: AdminApiPrefix) => {
  const [_, versionNumber] = prefix.split('/admin/api/v');
  return Number(versionNumber) as 1 | 2 | 3;
};

export type AdminResourceStatus = 'unknown' | 'active' | 'inactive';

export type AdminResource = {
  name: string;
  created_at?: string;
  created_by?: string;
  display_name: string;
  status?: AdminResourceStatus;
};

export const isAdminResourceActive = (resource: AdminResource) => resource?.status === 'active';

export const ADMIN_RESOURCE_API_NAMES = ['tenants', 'accesspolicies', 'tokens', 'clusters', 'licenses'] as const;
export type AdminApiPrefix = `${PluginApiPrefix}/admin/api/${AdminApiVersion}`;

export const getAdminApiPrefix: (pluginId: string, version: AdminApiVersion) => AdminApiPrefix = (pluginId, version) =>
  `${getPluginApiPrefix(pluginId)}/admin/api/${version}`;

export type AdminResourceApiName = (typeof ADMIN_RESOURCE_API_NAMES)[number] | LegacyAdminApiV1.LegacyAdminApiNames;

export const ADMIN_RESOURCES_SINGULAR: Record<AdminResourceApiName, string> = {
  accesspolicies: 'access policy',
  clusters: 'cluster',
  licenses: 'license',
  tenants: 'tenant',
  tokens: 'token',
  instances: 'tenant',
} as const;

export const ADMIN_RESOURCES_PLURAL: Record<AdminResourceApiName, string> = {
  accesspolicies: 'access policies',
  clusters: 'clusters',
  licenses: 'licenses',
  tenants: 'tenants',
  tokens: 'tokens',
  instances: 'tenants',
} as const;

export type AdminResourceTypeString = 'license' | 'access_policy' | 'tenant' | 'instance' | 'cluster' | 'token';

export type AdminResourceList<T> = {
  items: T[];
  type: AdminResourceTypeString;
};
