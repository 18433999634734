import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';

import { Button, Field, FieldValidationMessage, HorizontalGroup, Input, Legend, Select } from '@grafana/ui';

import * as api from '@common/api';
import { SecretInput } from '@common/components';
import { useLatestAdminApiVersion } from '@common/state/src/gex/gex.hooks';
import { ADMIN_API_VERSIONS, AdminApiVersion } from '@common/types';
import {
  BackendContext,
  capitalize,
  getLicenseTokenError,
  prepareTokenForAdminApiUpdate,
  removeTrailingSlashes,
  usePluginMeta,
  useUtilityStyles,
} from '@common/utils';

export const ConnectionSettings = () => {
  const { pluginMeta, setPluginJsonData, pluginResourceUrlPrefix } = usePluginMeta();
  const { jsonData, id, name } = pluginMeta || {};
  const { backend } = useContext(BackendContext);
  const s = useUtilityStyles();

  const [backendUrl, setBackendUrl] = useState<string>();
  const [adminApiVersion, setAdminApiVersion] = useState<AdminApiVersion>();
  const [newAccessToken, setNewAccessToken] = useState<string | undefined>();

  const [connectionError, setConnectionError] = useState<string>();
  const [tokenError, setTokenError] = useState<string>();
  const [versionError, setVersionError] = useState<string>();

  const [backendIdentifier, setBackendIdentifier] = useState<string>();
  const [savingApiSettings, setSavingApiSettings] = useState(false);

  const { latestVersion: latestAdminApiVersion } = useLatestAdminApiVersion();

  const reset = useCallback(() => {
    setBackendUrl(removeTrailingSlashes(jsonData?.backendUrl || ''));
    setAdminApiVersion(jsonData?.adminApiVersion);
    // Blank string indicates that we should let the user edit it.
    // Undefined means that it is already set, and we can disable the input.
    setNewAccessToken(jsonData?.base64EncodedAccessTokenSet ? undefined : '');

    setConnectionError(undefined);
    setTokenError(undefined);
  }, [setConnectionError, setTokenError, setBackendUrl, setAdminApiVersion, jsonData]);

  useEffect(() => {
    if (backend.backendError) {
      setBackendIdentifier('Cannot connect');
    } else if (!backend.isBackend) {
      setBackendIdentifier('Checking...');
    } else {
      setBackendIdentifier(`${backend.name} ${backend.version || '(version not reported)'}`);
    }
  }, [backend, setBackendIdentifier]);

  useEffect(() => {
    reset();
  }, [reset]);

  const onTokenReset = () => setNewAccessToken('');

  useEffect(() => {
    if (newAccessToken === '' || !newAccessToken) {
      setTokenError(undefined);
    } else {
      setTokenError(getLicenseTokenError(newAccessToken));
    }
  }, [newAccessToken]);

  const onChangeBackendUrl = (event: ChangeEvent<HTMLInputElement>) => {
    setBackendIdentifier(undefined); // Clear if user starts to modify
    setConnectionError(undefined);
    setVersionError(undefined);
    setBackendUrl(event.target.value.trim());
  };

  const onChangeToken = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();
    setTokenError(getLicenseTokenError(value));
    setNewAccessToken(value);
  };

  const onSaveApiSettings = async () => {
    if (!backendUrl) {
      return;
    }
    const fixedUrl = removeTrailingSlashes(backendUrl?.trim());
    setBackendUrl(fixedUrl);
    setSavingApiSettings(true);

    const { tokenError, urlError, versionError } = await api.validateApiSettings(pluginResourceUrlPrefix, {
      adminApiVersion: adminApiVersion || ADMIN_API_VERSIONS[0],
      token: newAccessToken || '',
      url: backendUrl,
    });

    if (tokenError || urlError || versionError) {
      setTokenError(tokenError);
      setConnectionError(urlError);
      setVersionError(versionError?.message);
      setSavingApiSettings(false);
      return;
    }

    if (id && setPluginJsonData) {
      await setPluginJsonData(
        {
          backendUrl: fixedUrl,
          base64EncodedAccessTokenSet: true,
          adminApiVersion,
        },
        !newAccessToken
          ? {} // Don't change the token
          : { base64EncodedAccessToken: prepareTokenForAdminApiUpdate(newAccessToken) } // Set the new token
      );
    }
    setSavingApiSettings(false);
  };

  return (
    <div className="gf-form-group">
      {/* API SETTINGS */}
      <div>
        <Legend>Connection settings</Legend>

        {/* Access Token */}
        <Field
          label="Access token"
          description={
            <>
              The access token is a string of format &quot;&lt;name&gt;:&lt;password&gt;&quot; that has been base64
              encoded. It is saved on the server.
            </>
          }
        >
          <>
            <SecretInput
              className={s.width500}
              id="base64EncodedAccessToken"
              data-testid="access-token"
              value={newAccessToken}
              placeholder={'Paste your token'}
              onChange={onChangeToken}
              isConfigured={Boolean(jsonData?.base64EncodedAccessTokenSet && newAccessToken === undefined)}
              onReset={onTokenReset}
            />
            {tokenError && (
              <FieldValidationMessage className={s.marginTopSm}>{capitalize(tokenError)}</FieldValidationMessage>
            )}
          </>
        </Field>

        {/* Enterprise database URL */}
        <Field label={`${name} URL`} description="" className={s.marginTopMd}>
          <>
            <Input
              disabled={savingApiSettings}
              className={s.width500}
              id="backendUrl"
              data-testid="backend-url"
              label={`${name} URL`}
              value={backendUrl}
              placeholder={`E.g.: http://${id?.split('-').slice(1, 3).join('-')}`}
              onChange={onChangeBackendUrl}
              suffix={backendIdentifier}
            />
            {connectionError && (
              <FieldValidationMessage className={s.marginTopSm}>{capitalize(connectionError)}</FieldValidationMessage>
            )}
          </>
        </Field>

        {/* Admin API Version */}
        <Field label={`Admin API version`} description="" className={s.marginTopMd}>
          <>
            <Select<AdminApiVersion | 'latest'>
              disabled={savingApiSettings}
              onChange={(selection) => {
                if (selection.value === 'latest') {
                  setAdminApiVersion(undefined);
                } else {
                  setAdminApiVersion(selection.value);
                }
              }}
              value={adminApiVersion || 'latest'}
              width={24}
              options={[
                {
                  label: 'Default to latest' + (latestAdminApiVersion ? ` (${latestAdminApiVersion})` : ''),
                  value: 'latest',
                },
                ...ADMIN_API_VERSIONS.map((version) => ({
                  label: version,
                  value: version,
                })),
              ]}
            />
            {versionError && (
              <FieldValidationMessage className={s.marginTopSm}>{capitalize(versionError)}</FieldValidationMessage>
            )}
          </>
        </Field>

        <HorizontalGroup className={s.marginTopMd}>
          <Button
            data-testid="test-and-save"
            type="submit"
            onClick={onSaveApiSettings}
            icon={savingApiSettings ? 'fa fa-spinner' : 'save'}
            // We would like to disable the submit button if the token is being edited but is either empty or incorrectly formatted.
            disabled={
              Boolean(
                !backendUrl ||
                  !(jsonData?.base64EncodedAccessTokenSet || newAccessToken !== undefined) ||
                  tokenError ||
                  newAccessToken === ''
              ) || savingApiSettings
            }
          >
            Test and save configuration
          </Button>
          <Button type="reset" onClick={reset} variant="destructive">
            Reset
          </Button>
        </HorizontalGroup>
      </div>
    </div>
  );
};
