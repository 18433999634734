import { AccessPolicy } from '../AccessPolicy';
import { AdminApiPrefix, getVersionNumber } from '../AdminApi';
import { Token } from '../Token';

import { isNotBlank, PotentiallyBlank } from './AdminApiWorkaround';

export const isV2OrEarlier = (prefix: AdminApiPrefix) => getVersionNumber(prefix) <= 2;

// Also missing status before v2
type MissingStatusInV2 = Token | AccessPolicy;

export const ensureAdminResourceHasStatus = (resource: PotentiallyBlank<MissingStatusInV2>) => {
  if (!isNotBlank<MissingStatusInV2>(resource)) {
    // Due to a bug in backend AdminAPI, a newly created admin object
    // returned by `create...` may be "blank".
    // To work around this possibility, this function must do nothing.
    // See https://github.com/grafana/backend-enterprise/pull/3102 - Which as a fix will appear from 2022-03 onward
    //
    // After we stop official support for any AdminAPI versions before v3, we can safely remove this if-block.
    return;
  }

  if (!resource.status) {
    // If we have a non-null resource, and it's missing its status,
    // we assume that it should be considered `'active'`.
    // This corrects old versions of the AdminAPI, prior to v3 where the status
    // field was not present for some of these objects (AccessPolicy, Token).
    // In these old versions, if the resource wasn't deleted, the API would not be retrieving them.
    resource.status = 'active';
  }
};

export const ensureAdminResourcesHaveStatus = (resources: MissingStatusInV2[] | undefined) => {
  if (!resources) {
    return;
  }
  resources.forEach(ensureAdminResourceHasStatus);
};
