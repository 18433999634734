import { RingType } from '@common/types';

import { RootState } from '../store';

import { ringMembersAdapter } from './rings.reducers';

export const useRingSelector = (ringType: RingType) => {
  const selectRoot = (state: RootState) => state.rings[ringType];

  const { selectAll } = ringMembersAdapter.getSelectors(selectRoot);

  return { selectAll };
};

export const selectRingMetadata = (state: RootState) => state.ringMetadata;
