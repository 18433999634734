import { useQuery } from '@tanstack/react-query';

import { validateApiSettings } from '@common/api';
import { usePluginMeta } from '@common/utils';

export const useCompatibleAdminApiVersions = () => {
  const { pluginResourceUrlPrefix } = usePluginMeta();
  const { data } = useQuery([pluginResourceUrlPrefix, 'compatible-admin-api-versions'], () =>
    validateApiSettings(pluginResourceUrlPrefix, { adminApiVersion: 'v0' })
  );

  const error = (() => {
    if (data?.urlError == null) {
      return null;
    }
    const { urlError } = data;
    if (urlError.endsWith('server misbehaving')) {
      return 'database unavailable';
    } else if (urlError === 'the backend API responded with "HTTP 404 Not Found"') {
      return 'internal resource not found';
    }
    return null;
  })();

  const versions = data?.versionError?.altVersions;

  return {
    versions,
    error: error as typeof error, // To ensure the specific strings are part of type
  };
};

export const useLatestAdminApiVersion = () => {
  const { versions, error } = useCompatibleAdminApiVersions();

  let latestVersion = versions?.reduce((prev, curr) => {
    if (Number(prev.slice(1)) < Number(curr.slice(1))) {
      return curr;
    }
    return prev;
  });

  return { latestVersion, error };
};
