import { createAsyncThunk } from '@reduxjs/toolkit';

import { forgetRingMember as forgetRingMemberApi, getRingStatus } from '@common/api';
import { RING_TYPES } from '@common/types';
import { createRequestError } from '@common/utils';

import { RootState } from '../store';

const ACTION_FETCH_ALL = 'fetchAll';
const ACTION_FORGET_MEMBER = 'forgetMember';

export const getActionName = (ringType: string, actionName: string) => `rings/${ringType}/${actionName}`;

export type FetchAllArgs = { hideError?: boolean } | undefined;

export type RingActions = ReturnType<typeof createActions>;

const ringActionsByType: { [ringType: string]: RingActions } = {};

const createActions = (ringType: string) => {
  const fetchAllActionName = getActionName(ringType, ACTION_FETCH_ALL);
  const forgetMemberActionName = getActionName(ringType, ACTION_FORGET_MEMBER);

  const fetchAll = createAsyncThunk(fetchAllActionName, async (args: FetchAllArgs, thunkApi) => {
    try {
      const hideError = Boolean(args?.hideError);
      const { pluginPrefix } = (thunkApi.getState() as RootState).apiPrefixes;
      const ringStatus = await getRingStatus(pluginPrefix, ringType, !hideError);
      return ringStatus;
    } catch (e) {
      return thunkApi.rejectWithValue(createRequestError(`Unable to fetch "${ringType}" ring members`, e));
    }
  });

  const forgetMember = createAsyncThunk(forgetMemberActionName, async (id: string, thunkApi) => {
    try {
      const { pluginPrefix } = (thunkApi.getState() as RootState).apiPrefixes;
      await forgetRingMemberApi(pluginPrefix, ringType, id);
      return id;
    } catch (e) {
      return thunkApi.rejectWithValue(
        createRequestError(`Unable to forget "${ringType}" ring member with id "${id}"`, e)
      );
    }
  });

  return {
    fetchAll,
    forgetMember,
  };
};

const getRingActions = (ringType: string) => {
  let actions = ringActionsByType[ringType];

  if (!actions) {
    actions = createActions(ringType);
    ringActionsByType[ringType] = actions;
  }

  return actions;
};

export const useRingActions = (ringType: string) => getRingActions(ringType);

export const useAllRingActions = () => {
  const ringTypes = RING_TYPES;

  // First we ensure that actions for all ring types exist
  for (let i = 0; i < ringTypes.length; ++i) {
    const ringType = ringTypes[i];
    getRingActions(ringType);
  }

  // Then we provide the mapping to be `used` as a hook
  return ringActionsByType;
};
