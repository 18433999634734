import { AdminApiVersion } from './AdminApi';

export type PluginApiPrefix = `api/plugins/${string}/resources`;

export const getPluginApiPrefix: (pluginId: string) => PluginApiPrefix = (pluginId) =>
  `api/plugins/${pluginId}/resources`;

export type AppPluginSettings = {
  adminApiVersion?: AdminApiVersion;
  backendUrl?: string;
  base64EncodedAccessTokenSet?: boolean;
  versionedInitStatus?: Record<string, InitStatus>;
};

export type InitStatus = {
  reimportedDashboards: string[];
};
