import { AnyAction, createEntityAdapter, createSlice, EntityState, Reducer } from '@reduxjs/toolkit';

import { AppEvents, BusEvent } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';

import { RING_TYPES, RingMember, RingMetadata } from '@common/types';
import { getMetadataFromRingMembers, sortRingMembers } from '@common/utils';

import { useRingActions } from './rings.actions';

export const ringMembersAdapter = createEntityAdapter();

export const createRingReducers = (ringTypes: string[]) => {
  const reducerMap: { [ringType: string]: Reducer<EntityState<RingMember>, AnyAction> } = {};

  ringTypes.forEach((ringType) => {
    const { fetchAll, forgetMember } = useRingActions(ringType);

    const { reducer } = createSlice({
      name: `${ringType}Ring`,
      extraReducers: (builder) =>
        builder
          // Fetch All
          .addCase(fetchAll.fulfilled, (state, action) => {
            ringMembersAdapter.removeAll(state);

            const { ringMembers } = action.payload;

            // Do nothing, if no response
            if (ringMembers) {
              sortRingMembers(ringMembers);
              ringMembersAdapter.upsertMany(state, ringMembers);
            }
          })
          // Pending
          .addCase(forgetMember.pending, (state, action) => {
            const ringMember = state?.entities?.[action.meta?.arg];
            if (!ringMember) {
              return;
            }
            ringMember.isForgetting = true;
            ringMembersAdapter.upsertOne(state, ringMember);
          })
          // Forget
          .addCase(forgetMember.fulfilled, (state, action) => {
            ringMembersAdapter.removeOne(state, action.payload);
          })
          .addCase(forgetMember.rejected, (state, action) => {
            const ringMember = state?.entities?.[action.meta?.arg];

            if (ringMember) {
              ringMember.isForgetting = false;
            }

            const event: BusEvent = {
              type: AppEvents.alertError.name,
              payload: ['Error', action?.payload?.errorTitle],
            };
            getAppEvents().publish(event);
          }),
      initialState: ringMembersAdapter.getInitialState({}),
      reducers: {},
    });

    reducerMap[ringType] = reducer;
  });

  return reducerMap;
};

const createRingMetadataReducer = () => {
  const ringTypes = RING_TYPES;

  const initialState = {} as { [ringType: string]: RingMetadata };
  ringTypes.forEach(
    // Initialize for no members (empty list)
    (ringType) =>
      (initialState[ringType] = {
        ...getMetadataFromRingMembers([]),
        serviceAvailable: false,
      })
  );

  const { reducer } = createSlice({
    name: 'ringMetadata',
    extraReducers: (builder) => {
      // Allow each ring type to contribute its action results
      ringTypes.forEach((ringType) => {
        const { fetchAll, forgetMember } = useRingActions(ringType);

        [fetchAll].forEach((thunk: any) => {
          builder.addCase(thunk.rejected, (state) => {
            state[ringType].serviceAvailable = false;
            state[ringType].unhealthyMemberIds = [];
            state[ringType].activeMemberIds = [];
          });
        });

        builder.addCase(fetchAll.fulfilled, (state, action) => {
          const { ringMembers, shardingDisabled } = action.payload;
          state[ringType].serviceAvailable = true;
          state[ringType].shardingDisabled = shardingDisabled;
          Object.assign(state[ringType], getMetadataFromRingMembers(ringMembers));
        });

        builder.addCase(forgetMember.fulfilled, (state, action) => {
          const forgotten = action.payload;

          const metadata = state[ringType];

          // Make a dumb copy of both lists, but omitting the forgotten id.
          metadata.activeMemberIds = metadata.activeMemberIds.filter((value) => value !== forgotten);
          metadata.unhealthyMemberIds = metadata.unhealthyMemberIds.filter((value) => value !== forgotten);
          metadata.serviceAvailable = true;
        });
      });
    },
    initialState,
    reducers: {},
  });

  return reducer;
};

export const ringMetadataReducer = createRingMetadataReducer();
