import { AccessPolicy, AdminApiPrefix, getVersionNumber, LegacyAdminApiV1, LegacyAdminApiV2 } from '@common/types';
import { getOriginalObjectIfBlank, isNotBlank, PotentiallyBlank } from '@common/types/src/legacy/AdminApiWorkaround';
import {
  adminApiDeactivate,
  adminApiGet,
  adminApiGetAllItems,
  adminApiPost,
  adminApiPut,
  excludeReadOnlyFields,
  RequestOptions,
} from '@common/utils';

const RESOURCE_TYPE = 'accesspolicies';

export const getAccessPolicies = async (prefix: AdminApiPrefix) => {
  const accessPolicies = await getRawAccessPolicies(prefix);
  accessPolicies.forEach(fixNullValues);
  return accessPolicies;
};

export const getRawAccessPolicies = async (prefix: AdminApiPrefix) => {
  const version = getVersionNumber(prefix);

  switch (version) {
    case 1:
      return getAccessPoliciesV1(prefix);
    case 2:
      return getAccessPoliciesV2(prefix);
  }

  // current v3+
  const response = await adminApiGetAllItems<AccessPolicy>(prefix, RESOURCE_TYPE);
  return response.data.items || [];
};

const getAccessPoliciesV1 = async (prefix: AdminApiPrefix) => {
  const response = await adminApiGetAllItems<LegacyAdminApiV1.AccessPolicyV1>(prefix, RESOURCE_TYPE);
  const items = response.data.items || [];
  // Will never be empty string when fetching
  const accessPolicies = items.map(LegacyAdminApiV1.convertAccessPolicyFromV1) as AccessPolicy[];
  accessPolicies.forEach(LegacyAdminApiV2.ensureAdminResourceHasStatus);
  return accessPolicies;
};

const getAccessPoliciesV2 = async (prefix: AdminApiPrefix) => {
  const response = await adminApiGetAllItems<AccessPolicy>(prefix, RESOURCE_TYPE);
  const accessPolicies = response.data.items || [];
  LegacyAdminApiV2.ensureAdminResourcesHaveStatus(accessPolicies);
  return accessPolicies;
};

export const getAccessPolicy = async (prefix: AdminApiPrefix, name: string, options?: RequestOptions) => {
  const accessPolicy = await getRawAccessPolicy(prefix, name, options);
  fixNullValues(accessPolicy);
  return accessPolicy;
};

const getRawAccessPolicy = async (prefix: AdminApiPrefix, name: string, options?: RequestOptions) => {
  const version = getVersionNumber(prefix);

  switch (version) {
    case 1:
      return getAccessPolicyV1(prefix, name, options);
    case 2:
      return getAccessPolicyV2(prefix, name, options);
  }

  // current v3+
  const response = await adminApiGet<AccessPolicy>(prefix, RESOURCE_TYPE, name, options);
  return response.data;
};

const getAccessPolicyV1 = async (prefix: AdminApiPrefix, name: string, options?: RequestOptions) => {
  const response = await adminApiGet<LegacyAdminApiV1.AccessPolicyV1>(prefix, RESOURCE_TYPE, name, options);
  const data = response.data || {};
  // Will never be empty string when fetching
  const accessPolicy = LegacyAdminApiV1.convertAccessPolicyFromV1(data) as AccessPolicy;
  LegacyAdminApiV2.ensureAdminResourceHasStatus(accessPolicy);
  return accessPolicy;
};

const getAccessPolicyV2 = async (prefix: AdminApiPrefix, name: string, options?: RequestOptions) => {
  const response = await adminApiGet<AccessPolicy>(prefix, RESOURCE_TYPE, name, options);
  LegacyAdminApiV2.ensureAdminResourceHasStatus(response.data);
  await LegacyAdminApiV2.ensureAdminResourceHasStatus(response.data);
  const accessPolicy = response.data || {};
  return accessPolicy;
};

export const createAccessPolicy = async (prefix: AdminApiPrefix, accessPolicy: AccessPolicy) => {
  const version = getVersionNumber(prefix);

  const create = async () => {
    switch (version) {
      case 1:
        return createAccessPolicyV1(prefix, accessPolicy);
      case 2:
        return createAccessPolicyV2(prefix, accessPolicy);
      default: // current v3+
        const data = excludeReadOnlyFields(accessPolicy);
        return (await adminApiPost<AccessPolicy>(prefix, RESOURCE_TYPE, { data })).data;
    }
  };

  const result = await create();
  if (isNotBlank(result)) {
    // Only fix nulls on non-blank values
    fixNullValues(result);
  }
  return getOriginalObjectIfBlank(result, accessPolicy);
};

const createAccessPolicyV1 = async (prefix: AdminApiPrefix, accessPolicy: AccessPolicy) => {
  const accessPolicyV1 = LegacyAdminApiV1.convertAccessPolicyToV1(accessPolicy);
  const data = excludeReadOnlyFields(accessPolicyV1);
  const response = await adminApiPost<PotentiallyBlank<LegacyAdminApiV1.AccessPolicyV1>>(prefix, RESOURCE_TYPE, {
    data,
  });
  // Pre 2022-03, admin resource POST methods return blank data
  if (!isNotBlank(response.data)) {
    // Don't bother trying to convert blank response
    return '';
  }
  const created = LegacyAdminApiV1.convertAccessPolicyFromV1(response.data);
  LegacyAdminApiV2.ensureAdminResourceHasStatus(created);
  return created;
};

const createAccessPolicyV2 = async (prefix: AdminApiPrefix, accessPolicy: AccessPolicy) => {
  const data = excludeReadOnlyFields(accessPolicy);
  const response = await adminApiPost<PotentiallyBlank<AccessPolicy>>(prefix, RESOURCE_TYPE, { data });
  // Pre 2022-03, admin resource POST methods return blank data
  if (!isNotBlank(response.data)) {
    // Don't bother trying to convert blank response
    return '';
  }
  const created = response?.data;
  LegacyAdminApiV2.ensureAdminResourceHasStatus(created);
  return created;
};

export const updateAccessPolicy = async (prefix: AdminApiPrefix, accessPolicy: AccessPolicy) => {
  const version = getVersionNumber(prefix);

  const update = async () => {
    switch (version) {
      case 1:
        return updateAccessPolicyV1(prefix, accessPolicy);
      case 2:
        return updateAccessPolicyV2(prefix, accessPolicy);
      default:
        // current v3+
        const data = excludeReadOnlyFields(accessPolicy);
        const response = await adminApiPut<AccessPolicy>(prefix, RESOURCE_TYPE, accessPolicy.name, { data });
        return response.data;
    }
  };

  const result = await update();
  fixNullValues(result);
  return result;
};

const updateAccessPolicyV1 = async (prefix: AdminApiPrefix, accessPolicy: AccessPolicy) => {
  const accessPolicyV1 = LegacyAdminApiV1.convertAccessPolicyToV1(accessPolicy);
  const data = excludeReadOnlyFields(accessPolicyV1);
  const response = await adminApiPut<LegacyAdminApiV1.AccessPolicyV1>(prefix, RESOURCE_TYPE, accessPolicy.name, {
    data,
  });
  // PUT requests on admin resources return the object as expected
  const updated = LegacyAdminApiV1.convertAccessPolicyFromV1(response.data);
  LegacyAdminApiV2.ensureAdminResourceHasStatus(updated);
  return updated;
};

const updateAccessPolicyV2 = async (prefix: AdminApiPrefix, accessPolicy: AccessPolicy) => {
  const data = excludeReadOnlyFields(accessPolicy);
  const response = await adminApiPut<AccessPolicy>(prefix, RESOURCE_TYPE, accessPolicy.name, { data });
  // PUT requests on admin resources return the object as expected
  const updated = response.data;
  LegacyAdminApiV2.ensureAdminResourceHasStatus(updated);
  return updated;
};

export const deactivateAccessPolicy = async (prefix: AdminApiPrefix, accessPolicy: AccessPolicy) => {
  const response = await adminApiDeactivate<AccessPolicy>(prefix, RESOURCE_TYPE, accessPolicy.name, {
    data: accessPolicy,
  });
  return response.data;
};

const fixNullValues = (accessPolicy: AccessPolicy) => {
  // The react form hook `useFieldArray` does not handle nulls gracefully,
  // to we replace nulls with empty arrays after any fetch/create/update operation.
  accessPolicy.realms = accessPolicy.realms || [];
  accessPolicy.realms.forEach((realm) => (realm.label_policies = realm.label_policies || []));
};
