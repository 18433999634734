import React, { useContext } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { LoadingIndicator } from '@common/components';
import { BackendContext, usePluginMeta } from '@common/utils';

export const BackendRequiredRoute = (props: RouteProps) => {
  const {
    backend: { backendError, isBackend },
  } = useContext(BackendContext);

  const backendUrl = usePluginMeta().pluginMeta?.jsonData?.backendUrl;

  if (backendError || !backendUrl) {
    // Do not even try.
    return null;
  }
  if (!isBackend) {
    return <LoadingIndicator />;
  }
  return <Route {...{ props }}>{props.children}</Route>;
};
