import React, { useContext, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { css } from '@emotion/css';

import { AppRootProps } from '@grafana/data';
import { config as RuntimeConfig } from '@grafana/runtime';

import {
  ActionButtonSet,
  BackendErrorWarning,
  EnterpriseLicenseAlerts,
  GrafanaVersionWarning,
  Header,
  LicenseAlerts,
  PluginPage,
  RingManager,
} from '@common/components';
import { AppPluginSettings, Page } from '@common/types';
import { BackendContext, findActivePages, getNavModel, getPluginLogo, usePluginMeta } from '@common/utils';

import { AccessPolicies } from '../AccessPolicies';
import { Config } from '../Config';
import { Licenses } from '../Licenses';
import { Tenants } from '../Tenants';

import { BackendRequiredRoute } from './BackendRequiredRoute';

// Legacy
const routes: Page[] = [
  {
    id: 'tenants',
    component: Tenants,
    icon: 'database',
    label: 'Tenants',
  },
  {
    id: 'access-policies',
    component: AccessPolicies,
    icon: 'key-skeleton-alt',
    label: 'Access policies',
  },
  {
    id: 'ring-health',
    component: () => <RingManager enableRefresh />,
    icon: 'chart-line',
    label: 'Ring health',
  },
  {
    id: 'licenses',
    component: Licenses,
    icon: 'file-alt',
    label: 'Licenses',
  },
  {
    id: 'cluster-configuration',
    component: Config,
    icon: 'cog',
    label: 'Cluster configuration',
  },
];

export const Routes = (props: AppRootProps<AppPluginSettings>) => {
  const {
    meta,
    meta: { id, name },
    onNavChanged,
    path: basePath,
    query,
  } = props;

  const {
    backend: { name: backendName, version: backendVersion, isBackend },
  } = useContext(BackendContext);
  const { pluginAppUrl } = usePluginMeta();

  // React Router
  const pluginName = name;
  const connectionIndicator = isBackend ? `${backendName} ${backendVersion || '(version unknown)'}` : '';

  useEffect(() => {
    if (!RuntimeConfig?.featureToggles.topnav && !!onNavChanged) {
      const activeRoutes = findActivePages(routes, query, basePath, id);
      const topmostActivePage = activeRoutes[0];
      const subtitle = connectionIndicator ? `Managing ${connectionIndicator}` : '';

      const navModel = getNavModel(pluginName, subtitle, routes, basePath, topmostActivePage, getPluginLogo(meta), id);

      onNavChanged(navModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, basePath, connectionIndicator, pluginName, meta]);

  const actionButtons = <ActionButtonSet className={css({ marginTop: '-28px' })} />;

  return (
    <PluginPage
      renderTitle={(title) => <Header title={title} name={name} meta={meta} />}
      info={[...(connectionIndicator ? [{ label: 'Connected', value: connectionIndicator }] : [])]}
      actions={actionButtons}
    >
      {/* Only show license alerts if there is a backend available to fetch our licenses from */}
      {isBackend && <LicenseAlerts />}
      <GrafanaVersionWarning />
      <EnterpriseLicenseAlerts />
      <BackendErrorWarning />
      <Switch>
        <BackendRequiredRoute {...props} path={`${pluginAppUrl}/tenants`}>
          <Tenants />
        </BackendRequiredRoute>
        <BackendRequiredRoute {...props} path={`${pluginAppUrl}/access-policies`}>
          <AccessPolicies />
        </BackendRequiredRoute>
        <BackendRequiredRoute {...props} path={`${pluginAppUrl}/ring-health`}>
          <RingManager enableRefresh />
        </BackendRequiredRoute>
        <BackendRequiredRoute {...props} path={`${pluginAppUrl}/licenses`}>
          <Licenses />
        </BackendRequiredRoute>
        <BackendRequiredRoute {...props} path={`${pluginAppUrl}/cluster-configuration`}>
          <Config />
        </BackendRequiredRoute>
        {/* Default route (only redirect if the path matches the plugin's URL) */}
        <Route path={`${pluginAppUrl}`}>
          <Redirect to={`${pluginAppUrl}/tenants`} />
        </Route>
      </Switch>
    </PluginPage>
  );
};
