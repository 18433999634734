import { default as gemPluginJson } from '../../../../plugins/grafana-metrics-enterprise-app/src/plugin.json';

import { default as basePluginMock } from './plugin.mock.json';

export const pluginMock = {
  ...basePluginMock,
  meta: {
    ...gemPluginJson,
    jsonData: {
      adminApiVersion: 'v1',
      backendUrl: 'http://metrics-enterprise',
      base64EncodedAccessTokenSet: true,
    },
  },
};
