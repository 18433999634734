import { AccessPolicy, LabelPolicy, Realm } from '../AccessPolicy';
import { AdminResource } from '../AdminApi';
import { Token } from '../Token';

export type RealmV1 = {
  cluster?: string;
  instance?: string;
  label_policies?: LabelPolicy[];
};

export type AccessPolicyV1 = AdminResource & {
  realms: RealmV1[];
  scopes?: string[];
  tokens?: Token[];
};

const convertRealmFromV1 = ({ cluster, instance, label_policies }: RealmV1): Realm => {
  return { cluster, label_policies, tenant: instance };
};

const convertRealmToV1 = ({ cluster, label_policies, tenant }: Realm): RealmV1 => {
  return { cluster, instance: tenant, label_policies };
};

export const convertAccessPolicyFromV1 = (accessPolicyV1: AccessPolicyV1): AccessPolicy => {
  // This is assumed to operate on real non-blank v1 access policy objects
  // from "GET" or "PUT" operations.
  return {
    ...accessPolicyV1,
    realms: accessPolicyV1.realms?.map(convertRealmFromV1),
  };
};

export const convertAccessPolicyToV1 = (accessPolicy: AccessPolicy): AccessPolicyV1 => {
  return {
    ...accessPolicy,
    realms: accessPolicy.realms?.map(convertRealmToV1),
  };
};

export type LegacyAdminApiNames = 'instances';
