import React from 'react';
import { MemoryRouter } from 'react-router-dom';

import { AppPluginMeta } from '@grafana/data';

import { pluginMock } from '@common/state';
import MOCK_CONFIG from '@common/state/src/mocks/config.mock.json';
import { Backend, getAdminApiPrefix } from '@common/types';
import { BackendContext, getBackendContextObject, PluginMetaContext } from '@common/utils';

import { MockStore } from './MockStore';

// Can be used to wrap components in tests or stories to provide a mocked redux store and
// all other neccessary contexts
export const TestWrapper: React.FC<{ backend?: Backend; children: React.ReactNode }> = ({ backend, children }) => {
  const pluginMeta = pluginMock.meta as unknown as AppPluginMeta;

  const backendContext = getBackendContextObject(backend, getAdminApiPrefix(pluginMeta.id, 'v3'), false, {
    ...MOCK_CONFIG,
  });

  // Specify a BackendContextObject
  return (
    <MockStore>
      <MemoryRouter>
        <PluginMetaContext.Provider value={{ pluginMeta }}>
          <BackendContext.Provider value={backendContext}>{children}</BackendContext.Provider>
        </PluginMetaContext.Provider>
      </MemoryRouter>
    </MockStore>
  );
};
