import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RingType } from '@common/types';

import { useIsNotFetched } from '../requests';

import { useRingActions } from './rings.actions';
import { useRingSelector } from './rings.selectors';

export const useGetAll = (ringType: RingType) => {
  const { selectAll } = useRingSelector(ringType);

  // Fetch once if it hasn't been fetched yet
  useFetchAll(ringType);
  return useSelector(selectAll);
};

// Returns with a function that can be used to forget an ingester
export const useForgetMember = (ringType: string) => {
  const dispatch = useDispatch();
  const { forgetMember } = useRingActions(ringType);

  return (id: string) => dispatch(forgetMember(id));
};

export const useFetchAll = (ringType: string) => {
  const dispatch = useDispatch();
  const { fetchAll } = useRingActions(ringType);

  const isNotFetched = useIsNotFetched(fetchAll.typePrefix);

  useEffect(() => {
    isNotFetched && dispatch(fetchAll());
  }, [ringType, isNotFetched, dispatch, fetchAll]);
};
