import { KeyValue, PluginMeta } from '@grafana/data';

import { AdminApiVersion, PluginApiPrefix } from '@common/types';
import { apiGet, apiPost, fromYamlStream, pluginGet } from '@common/utils';

export const updatePlugin = async <T extends KeyValue>(pluginId: string, data: Partial<PluginMeta<T>>) => {
  const response = await apiPost<PluginMeta<T>>(`/api/plugins/${pluginId}/settings`, { data });
  return response?.data;
};

export type ValidateApiSettingsResponse = {
  tokenError?: string;
  urlError?: string;
  versionError?: {
    altVersions: AdminApiVersion[];
    message: string;
  };
};

export const getPlugin = async <T extends KeyValue>(pluginId: string) => {
  const response = await apiGet<PluginMeta<T>>(`/api/plugins/${pluginId}/settings`);
  return response.data;
};

export const getPluginDashboards = async (pluginId: string) => {
  const response = await apiGet<PluginDashboard[]>(`/api/plugins/${pluginId}/dashboards`);
  return response.data;
};

export const importDashboard = async (request: ImportDashboardRequest) => {
  // Response type is ImportDashboardResponse (see grafana repo), but we don't use it currently
  const response = await apiPost(`/api/dashboards/import`, { data: request });
  return response.data;
};

// PluginDashboard plugin dashboard model..
type PluginDashboard = {
  uid?: number;
  title: string;
  dashboardId: number;
  description: string;
  folderId: number;
  imported: boolean;
  importedRevision: number;
  importedUri: string;
  // Not provided by `getPluginDashboards`
  importedUrl: string;
  path: string;
  pluginId: string;
  reference?: string;
  removed: boolean;
  revision: number;
  slug: string;
};

// ImportDashboardResponse response object returned when importing a dashboard.
export type ImportDashboardRequest = {
  // Sometimes folderId is required on re-import to avoid a cryptic error:
  //   "Unique identifier needed to be able to get a dashboard"
  // See: https://github.com/grafana/gex-plugins/issues/616
  folderId?: number;
  inputs: ImportDashboardInput[];
  overwrite: boolean;
  path: string;
  pluginId: string;
};

// ImportDashboardInput definition of input parameters when importing a dashboard.
type ImportDashboardInput = {
  name: string;
  pluginId: string;
  type: string;
  value: string;
};

export const getConfig = async (prefix: PluginApiPrefix, configFetchUrl: string) => {
  const response = await pluginGet<string>(prefix, configFetchUrl, { showErrorAlert: false });

  // Convert the response from YAML immediately after fetch
  const configAsString = response.data;
  const yamlObjects = fromYamlStream(configAsString).filter((obj) => typeof obj === 'object');
  if (yamlObjects.length !== 1) {
    console.error(`${configFetchUrl} returned`, yamlObjects);
    throw new Error(
      `Ambiguous configuration returned from ${configFetchUrl}. Expected one yaml object, received ${yamlObjects.length}.`
    );
  }
  return yamlObjects[0];
};
