import { screen, within } from '@testing-library/react';

import { OrgRole } from '@grafana/data';
import { GrafanaBootConfig } from '@grafana/runtime';

export const queryByFullText = (text: string, container?: HTMLElement) =>
  (container ? within(container) : screen).queryByText((_, node: Element | undefined | null) => {
    if (node) {
      const nodeHasText = (node: HTMLElement | Element) => node.textContent?.includes(text);
      const currentNodeHasText = nodeHasText(node);
      const childrenDontHaveText = Array.from(node.children).every((child) => !nodeHasText(child));

      return Boolean(currentNodeHasText && childrenDontHaveText);
    }

    return false;
  });

export const mockRuntimeUserRole = (config: GrafanaBootConfig, role: OrgRole) => {
  config.bootData = {
    ...config.bootData,
    user: {
      ...config.bootData.user,
      orgRole: role,
    },
  };
};
