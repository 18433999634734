import React, { useContext, useState } from 'react';

import { Drawer, useStyles2 } from '@grafana/ui';

import {
  ApiError,
  CreateTenantInfoBox,
  CreateTenantModal,
  LoadingIndicator,
  TenantForm,
  TenantListItem,
  TenantsActionBar,
} from '@common/components';
import { useIsLoading } from '@common/state/src/requests';
import {
  useDeactivate,
  useFetchById,
  useGetAll as useGetAllTenants,
  useTenantsStats,
  useUpdate,
} from '@common/state/src/tenants';
import { Limits, Tenant } from '@common/types';
import { BackendContext, getLimitedLengthName, popAlert, timeAgo } from '@common/utils';

import { getStyles } from './Tenants.styles';

export const Tenants = () => {
  const {
    backend: { features },
  } = useContext(BackendContext);
  const tenantStatsDictionary = useTenantsStats();
  const isSelfMonitoringEnabled = !!features.self_monitoring;
  const isEditEnbabled = !!features.editable_tenants;
  const styles = useStyles2(getStyles);
  // Use this to remove the system monitoring tenant for clusters that don't support self-monitoring
  const filterSystemMonitoringTenant = (item: Tenant) => isSelfMonitoringEnabled || item.name !== '__system__';
  const { activeResources, isLoading, isError } = useGetAllTenants();

  const items = activeResources.filter(filterSystemMonitoringTenant);
  const isLoadingDetails = useIsLoading('tenants/fetchTenantById');
  const deactivate = useDeactivate();
  const onUpdate = useUpdate();
  const fetchSingle = useFetchById();
  const [selectedItem, setSelectedItem] = useState<Tenant>();
  const [isCreateModalOpen, setIsCreateModalVisible] = useState(false);
  const showCreateModal = () => setIsCreateModalVisible(true);
  const onHideModal = () => setIsCreateModalVisible(false);
  // We would only like to show the loading indicator for the list before the initial fetch.
  // (The state would end up in a loading state even when we are creating / deleting items,
  // however we wouldn't like the list to flicker when this is happening.)
  const shouldShowLoading = isLoading && !items.length;
  const hasNoItems = !isLoading && items.length === 0;
  const hasItems = items.length > 0;

  const selectItemForEdit = isEditEnbabled
    ? (item: Tenant) => {
        // We are fetching the tenant to get the latest changes & version before starting to edit
        fetchSingle(item.name);
        setSelectedItem(item);
      }
    : undefined;

  if (isError) {
    return <ApiError actionType="tenants/fetchAll" />;
  }

  return (
    <div>
      {/* Create Tenant Modal */}
      <CreateTenantModal isOpen={isCreateModalOpen} onDismiss={onHideModal} />

      {/* Top actions bar */}
      {hasItems && <TenantsActionBar onCreate={showCreateModal} />}

      {/* Loading */}
      {shouldShowLoading && <LoadingIndicator />}

      {/* No items */}
      {hasNoItems && <CreateTenantInfoBox onCreate={showCreateModal} />}

      {/* List items */}
      {hasItems &&
        items.map(
          (item) =>
            item.name && (
              <TenantListItem
                key={item.name}
                item={item}
                stats={tenantStatsDictionary?.get(item.name)}
                onDelete={deactivate}
                onEdit={selectItemForEdit}
              />
            )
        )}

      {/* Item details */}
      {selectedItem && (
        <Drawer
          expandable
          scrollableContent
          // TODO This is workaround, eventually we want to fix this in the grafana
          title={getLimitedLengthName(selectedItem)}
          subtitle={`Created ${timeAgo(selectedItem.created_at)}`}
          onClose={() => setSelectedItem(undefined)}
        >
          {isLoadingDetails && <LoadingIndicator />}

          {/* Displaying as an editable form */}
          {!isLoadingDetails && (
            <TenantForm
              defaultValues={selectedItem}
              className={styles.tenantForm}
              onCancel={() => setSelectedItem(undefined)}
              onSubmit={(data) => {
                const compositeTenant = {
                  ...selectedItem,
                  ...data,
                };

                // Limits must be left undefined if there are no keys. Sending `{}` means use admin-api default limits.
                // See https://github.com/grafana/gex-plugins/issues/375 for details
                let limits: Limits | undefined = undefined;
                if (compositeTenant.limits && Object.keys(compositeTenant.limits).length) {
                  limits = { ...compositeTenant.limits };
                }

                const { cluster, display_name, status } = compositeTenant;
                const { name } = selectedItem;

                // Include all write-able fields that we do not want to blank out.
                // (In the future, status may not be required here)
                onUpdate({ name, cluster, display_name, limits, status })
                  // @ts-ignore - AsyncThunk actually returns a promise
                  .then(() => {
                    setSelectedItem(undefined);
                    popAlert('tenant', 'updated', data.display_name);
                  });
              }}
            />
          )}
        </Drawer>
      )}
    </div>
  );
};
