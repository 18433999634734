import { lastValueFrom } from 'rxjs';

import { BackendSrvRequest, FetchResponse, getBackendSrv } from '@grafana/runtime';

import { AdminApiPrefix, AdminResource, AdminResourceApiName, LegacyAdminApiV2, PluginApiPrefix } from '@common/types';

// Subset of BackendSrvRequest
export type RequestOptions = Omit<BackendSrvRequest, 'url'>;

export const apiRequest = <T>(url: string, options: RequestOptions) => {
  // Note, Grafana >=8.2 is required for `fetch` to be present
  const observable = getBackendSrv().fetch<T>({ ...options, url });
  return lastValueFrom(observable);
};

export const apiPost = <T>(url: string, options: RequestOptions = {}) =>
  apiRequest<T>(url, {
    ...options,
    method: 'POST',
  });

export const apiGet = <T>(url: string, options: RequestOptions = {}) =>
  apiRequest<T>(url, {
    ...options,
    method: 'GET',
  });

export const apiDelete = (url: string, options: RequestOptions = {}) =>
  apiRequest(url, {
    ...options,
    method: 'DELETE',
  });

export const apiPut = <T>(url: string, options: RequestOptions = {}) =>
  apiRequest<T>(url, {
    ...options,
    method: 'PUT',
  });

export const pluginGet = <T>(prefix: PluginApiPrefix, pluginResource: string, options?: RequestOptions) =>
  apiGet<T>(`${prefix}/${pluginResource}`, options);

export const pluginPost = <T>(prefix: PluginApiPrefix, pluginResource: string, options?: RequestOptions) =>
  apiPost<T>(`${prefix}/${pluginResource}`, options);

// Admin API related code
// -------------------------------------------------------------------

// We need this to keep track of what the latest version on the backend is.
const resourceVersions: Map<string, number> = new Map();

export const setResourceVersion = (url: string, version: number) => {
  resourceVersions.set(url, version);
};

export const getResourceVersion = (url: string) => {
  const version = resourceVersions.get(url);
  if (!version) {
    throw new Error('Version not found.');
  }
  return version;
};

export const getAdminApiUrl = (
  prefix: AdminApiPrefix,
  resourceType: AdminResourceApiName,
  specificResourceName?: string
) => {
  if (!prefix || !resourceType) {
    throw new Error(`Not able to create admin api URL with prefix "${prefix}" or resource type "${resourceType}"`);
  }
  const url = `${prefix}/${resourceType}` + (specificResourceName ? `/${specificResourceName}` : '');
  return url;
};

export const adminApiPost = async <T>(
  prefix: AdminApiPrefix,
  urlSuffix: AdminResourceApiName,
  options: RequestOptions = {}
) => {
  const url = getAdminApiUrl(prefix, urlSuffix);
  const response = await apiPost<T>(url, options);

  // All our resources are referenced by a "name" property.
  // As the POST requests are used to create a new resource, we can assume that the version is always going to be "1".
  setResourceVersion(`${url}/${options.data?.name}`, 1);

  return response;
};

export const getETag = (response: FetchResponse<any>): string | undefined => {
  // The purpose of this is to strip an optional prefix of `/W` indicating that the etag is weak.
  // See: https://github.com/grafana/gex-plugins/issues/358 for more detail
  return response.headers.get('etag')?.replace(/(^W\/|\")/g, '');
};

/* Admin API types that can be mutated, deleted, deactivated */
const mutableTypes = new Set<AdminResourceApiName>(['tokens', 'accesspolicies', 'tenants', 'instances']);

export const adminApiGetAllItems = async <T extends AdminResource>(
  prefix: AdminApiPrefix,
  adminResourceType: AdminResourceApiName,
  options: RequestOptions = {},
  includeNonActive = true // default behavior, so that we can detect ALL used-up names
) => {
  const itemTypeUrl = getAdminApiUrl(prefix, adminResourceType);
  // includeNonActive=true will fetch admin resources with status=inactive
  const params = { ...options.params, 'include-non-active': includeNonActive };
  const response = await apiGet<{ items: T[] }>(itemTypeUrl, { ...options, params });

  if (!mutableTypes.has(adminResourceType)) {
    return response;
  }

  // Only keep the resource versions for the mutable types
  const latestVersions = getETag(response);
  if (latestVersions) {
    latestVersions
      ?.split(',')
      .map(Number)
      .forEach((version, index) => {
        const item = response.data.items[index];
        const itemUrl = `${itemTypeUrl}/${item.name}`;
        setResourceVersion(itemUrl, version);
      });
  }

  return response;
};

export const excludeReadOnlyFields = <T extends AdminResource>(resource: T) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { created_at, created_by, ...writable } = resource;
  return writable;
};

export const adminApiGet = async <T>(
  prefix: AdminApiPrefix,
  resourceType: AdminResourceApiName,
  resourceName: string,
  options: RequestOptions = {}
) => {
  const url = getAdminApiUrl(prefix, resourceType, resourceName);
  const response = await apiGet<T>(url, options);

  if (!mutableTypes.has(resourceType)) {
    return response;
  }

  // Only keep the resource versions for the mutable types
  const latestVersion = getETag(response);

  if (latestVersion) {
    setResourceVersion(url, Number(latestVersion));
  }

  return response;
};

export const adminApiDeactivate = async <T extends AdminResource>(
  prefix: AdminApiPrefix,
  resourceType: AdminResourceApiName,
  resourceName: string,
  options: RequestOptions = {}
) => {
  const resource = options.data as T;
  const deactivatedResource = { ...resource, status: 'inactive' };

  if (LegacyAdminApiV2.isV2OrEarlier(prefix)) {
    // For v2 and earlier, adminApiDeactivate can only use DELETE
    const url = getAdminApiUrl(prefix, resourceType);
    apiDelete(url, includeResourceVersionHeaders(options, url));
    // If successful, return a deactivated copy version of the record.
    return { data: deactivatedResource };
  }
  // Since v3, deactivation of admin resources use PUT to set the status to inactive.
  // See: https://github.com/grafana/gex-plugins/issues/512
  return adminApiPut<T>(prefix, resourceType, resourceName, {
    ...options,
    data: deactivatedResource,
  });
};

export const adminApiPut = async <T>(
  prefix: AdminApiPrefix,
  resourceType: AdminResourceApiName,
  resourceName: string,
  options: RequestOptions = {}
) => {
  const url = getAdminApiUrl(prefix, resourceType, resourceName);
  const response = await apiPut<T>(url, includeResourceVersionHeaders(options, url));
  const latestVersion = getETag(response);

  if (latestVersion) {
    setResourceVersion(url, Number(latestVersion));
  }

  return response;
};

const includeResourceVersionHeaders = (options: RequestOptions, resourceUrl: string) => {
  try {
    return {
      ...options,
      headers: { 'If-Match': getResourceVersion(resourceUrl) },
    };
  } catch {
    // This is expected behavior in backend enterprise versions prior to the completion Admin API v3.
    // In the builds prior to the development of v3, i.e., before 2022-04-15,
    // the fetched Token objects did come with version numbers.
    // Editing tokens was not a priority.
    //
    // The absence of resource versions in tokens is not a problem here because:
    // 1. It is possible to delete Tokens without the resource version (when using Admin API v1, v2)
    // 2. Backend releases that include Admin API v3 (which uses PUT to deactivate) include the token resource versions
    //
    // A development time warning is printed to bring attention.
    // This warning is only expected to appear when using old versions of the backend.
    console.warn(
      'Unable to obtain admin resource version for ',
      resourceUrl,
      ' -- This is not a problem unless there are additional errors related to DELETE or PUT operations.'
    );
    return { ...options };
  }
};
