import React from 'react';
import { any, splitEvery } from 'ramda';

import { RingMember, RingMetadata, TableData } from '@common/types';

export const splitTokensIntoColumns = (tokens: number[]) => {
  const columnNumber = 3;
  const numberOfTokensPerColumn = Math.ceil(tokens.length / columnNumber);
  const tokensWithIndexes = tokens.map((token, index) => [index, token]);

  return splitEvery(numberOfTokensPerColumn, tokensWithIndexes);
};

// These are the field names in the data that comes down the pipe
// and also the identifiers that we use in our client side code.
export enum RingMemberField {
  Actions = 'actions',
  Address = 'address',
  Id = 'id',
  LastHeartbeat = 'timestamp',
  Ownership = 'ownership',
  State = 'state',
  Tokens = 'tokens',
  Zone = 'zone',
}

// Determines the order of the columns, as well as their labels.
const ringMemberColumns: Array<[RingMemberField, string]> = [
  [RingMemberField.Id, 'ID'],
  [RingMemberField.State, 'State'],
  [RingMemberField.LastHeartbeat, 'Last Heartbeat'],
  [RingMemberField.Zone, 'Zone'],
  [RingMemberField.Address, 'Address'],
  [RingMemberField.Tokens, 'Tokens'],
  [RingMemberField.Ownership, 'Ownership'],
  [RingMemberField.Actions, ''],
];

export type RingMemberRenderers = {
  [key: string]: (ingester: RingMember) => React.ReactNode | null | number | string;
};

// TableData is almost identical to the one that the <Table> component from @grafana/ui expects for the easy transition
// once that component is starting to support custom renderers for columns.
export const transformRingResponse = (ringMember: RingMember[], renderers: RingMemberRenderers = {}): TableData => ({
  fields: ringMemberColumns.map(([key, label]) => ({
    id: key,
    name: label,
    render: renderers[key],
    values: ringMember,
  })),
});

// Use number values when sorting: compactor-1 < compactor-2 < compactor-10
const compareWithNumberParsing = (a: string, b: string) =>
  a.localeCompare(b, navigator.languages[0] || navigator.language, { ignorePunctuation: true, numeric: true });

export const sortRingMembers = (members: RingMember[]) => {
  // First sort by name
  members.sort((a: RingMember, b: RingMember) => compareWithNumberParsing(a.id, b.id));

  // Sort unhealthy members to the top
  members.sort((a: RingMember, b: RingMember) => {
    const aUnhealthy = a.state === 'UNHEALTHY';
    const bUnhealthy = b.state === 'UNHEALTHY';
    if (aUnhealthy && bUnhealthy) {
      return 0;
    } else if (aUnhealthy) {
      return -1;
    } else if (bUnhealthy) {
      return +1;
    } else {
      return 0;
    }
  });
};

export const isRingPending = ({ activeMemberIds, serviceAvailable }: RingMetadata) => {
  return activeMemberIds.length === 0 && serviceAvailable;
};

export const areAllRingsInactive = (rings: RingMetadata[]) => {
  return !any(({ activeMemberIds }) => activeMemberIds.length > 0, Object.values(rings));
};

export const getMetadataFromRingMembers = (ringMembers: RingMember[]) => {
  const metadata = {
    activeMemberIds: [],
    unhealthyMemberIds: [],
  };

  const mapping: { [state: string]: string[] } = {
    ACTIVE: metadata.activeMemberIds,
    UNHEALTHY: metadata.unhealthyMemberIds,
  };

  ringMembers?.forEach((member) => {
    const list: string[] = mapping[member.state];

    if (list) {
      list.push(member.id);
    }
  });

  return metadata;
};
