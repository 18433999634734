import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { rangeUtil } from '@grafana/data';
import { RefreshPicker } from '@grafana/ui';

import { selectMultipleRequestInfos } from '@common/state/src/requests';
import { useAllRingActions } from '@common/state/src/rings';
import { any, BackendContext } from '@common/utils';

/**
 * Blank string indicates no refresh interval
 */
const INTERVAL_OFF = '';

export const RingHealthRefresh = () => {
  const dispatch = useDispatch();
  const {
    backend: {
      implicitFeatures: { ringTypes },
    },
    refreshConfig,
  } = useContext(BackendContext);

  // Get request states
  const ringActionsByType = useAllRingActions();
  const fetchRequests = useSelector(
    selectMultipleRequestInfos([...Object.values(ringActionsByType).map((actions) => actions.fetchAll)])
  );
  const anyRequestsLoading = any((info) => info.isLoading, fetchRequests);

  // For the refresh interval picker
  const [refreshInterval, setRefreshInverval] = useState('1m');

  // State to control whether or not a refresh is needed.
  const [refreshNeeded, setRefreshNeeded] = useState(true);

  // If we need a refresh, and we're not already loading, then refresh!
  if (refreshNeeded && !anyRequestsLoading && ringTypes.length > 0) {
    // dispatch fetchAll for all ringtypes.
    ringTypes.forEach((ringType) => {
      const { fetchAll } = ringActionsByType[ringType];
      // We try to fetch everything; we have no way of knowing is services are available.
      dispatch(fetchAll({ hideError: true }));
    });

    refreshConfig();
    setRefreshNeeded(false);
  }

  // If the user refresh state is changed, or the cluster is changed
  // we shut down the old refresher, or set up a new one.
  useEffect(() => {
    if (refreshInterval === INTERVAL_OFF) {
      return;
    }
    const refreshWait = rangeUtil.intervalToMs(refreshInterval);

    const interval = setInterval(() => {
      setRefreshNeeded(true);
    }, refreshWait);

    return () => clearInterval(interval);
  }, [refreshInterval]);

  return (
    <RefreshPicker
      onRefresh={() => setRefreshNeeded(true)}
      onIntervalChanged={(interval) => setRefreshInverval(interval)}
      value={refreshInterval}
      isLoading={anyRequestsLoading}
    />
  );
};
